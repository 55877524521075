import React from "react";
import myImg from "../assets/Products/our-products.png";
import {
  Box,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Grid,
  useBreakpointValue,
  SimpleGrid,
  Link
} from "@chakra-ui/react";
import SCPCard from "../Components/Card/SCPCard";
import Devices from "../Components/Card/Devices";
import qrious from "../assets/Products/QriousDes.svg";
import bertNova from "../assets/Products/bert-nova.svg";
import models from "../assets/Products/geminus/models.png";
import screens from "../assets/Products/geminus/screens.png";
import DTR from "../assets/Products/optimus/DTR.png";
import RLA from "../assets/Products/optimus/RLA.png";
import application from "../assets/Products/DS/application.png";
import authentication from "../assets/Products/DS/authentication.png";
import cryptography from "../assets/Products/DS/cryptography.png";
import databaseSecurity from "../assets/Products/DS/database security.png";
import infrastructure from "../assets/Products/DS/infrastructure.png";
import networkSecurity from "../assets/Products/DS/network security.png";
import vulnerabilityAssessment from "../assets/Products/DS/vulnerability assessment.png";
import webSecurity from "../assets/Products/DS/web security.png";
import picnew from "../assets/Products/Bert-Qrious---New-(Sept-23) 2.png";
import Allprod from "../Components/Allprod";

import HexagonGrid from "../Components/Hexagon/HexagonGrid";

const Products = () => {
  const images = [
    { src: application, title: "Application Security" },
    { src: databaseSecurity, title: "Database Security" },
    { src: networkSecurity, title: "Network Security" },
    { src: vulnerabilityAssessment, title: "Vulnerability Assessment" },
    { src: webSecurity, title: "Web Security" },
    { src: authentication, title: "Authentication and Access" },
    { src: cryptography, title: "Cryptography" },
    { src: infrastructure, title: "Infrastructure & Operations" },
  ];
  const columnCount = useBreakpointValue({ base: 2, md: 3, lg: 4 });

  return (
    <Box padding={{ base: "17px", lg: "64px" }} fontFamily={"Mulish"}>
      <Box display={"flex"} justifyContent={"space-around"}>
        <Box display={{ base: "block", lg: "none" }}>
          <img src={myImg} alt="img" width={{ lg: "55%", base: "95%" }} />
        </Box>
      </Box>
      <Allprod />
      
        <Box
          padding={"15px"}
          borderRadius={{ base: "17px", lg: "25px" }}
          mt={"25px"}
        ><section id="bert-maximus" className="scroll">
          <Link href="https://bertmaximus.bertlabs.com">
          <Text fontSize={{ lg: "34px", base: "17px" }} fontWeight={"bold"}>
            Bert Maximus
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
          </Text>
            </Link>
          </section>
          <Devices />
        </Box>
      
      
        <Box
          padding={"10px"}
          borderRadius={{ base: "17px", lg: "25px" }}
          mt={"25px"}
        ><section id="bert-qrious" className="scroll">
          <Text fontSize={{ lg: "34px", base: "17px" }} fontWeight={"bold"}>
            Bert Qrious
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
          </Text></section>
          <Text fontSize={{ lg: "20px", base: "16px" }}>
            Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> is proprietary, patented IoT powered Wireless Sensor
            Network, with several salient features like data security and remote
            software updates. Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> gives Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> the
            ability to real time transmit data.
          </Text>
          <Box height={{ lg: "auto", base: "430px" }}>
            <Image src={qrious} width={"100%"} />
          </Box>
        </Box>
      
      
        <Box
          padding={"15px"}
          borderRadius={{ base: "17px", lg: "25px" }}
          mt={"25px"}
        ><section id="bert-nova" className="scroll">
          <Text
            fontSize={{ lg: "34px", base: "17px" }}
            fontWeight={"bold"}
            marginTop={{ lg: "25px", base: "0px" }}
          >
            Bert Nova
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
          </Text></section>
          <Text mt={"21px"} fontSize={{ lg: "20px", base: "16px" }}>
            Proprietary, patented multi-tenant Distributed, Microservices
            Computing Platform, which gives Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> the ability
            to receive data from millions of sensors in few thousands of
            manufacturing plants and commercial buildings to store Big Data,
            Process Data, Analyze Data, and real time compute on Data from
            public Cloud (Microsoft Azure, Amazon AWS, Google Cloud),
            on-premises server, Edge Device (Bert Titan and Bert Aksh).
          </Text>
          <Box justifyContent={"center"} alignItems={"center"}>
            <Image
              src={bertNova}
              height={{ base: "90%", lg: "100%" }}
              width={{ base: "90%", lg: "90%" }}
              margin="auto"
            />
          </Box>
        </Box>
      
      
        <Box
          marginTop={"25px"}
          borderRadius={{ base: "17px", lg: "25px" }}
          padding={"15px"}
        ><section id="bert-geminus" className="scroll">
          <Text
            fontSize={{ lg: "34px", base: "17px" }}
            fontWeight={"bold"}
            fontStyle={"25px"}
            marginLeft={{ sm: "10px", lg: "0px" }}
          >
            Bert Geminus
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
          </Text></section>
          <Text fontSize={{ lg: "20px", base: "16px" }}>
            Proprietary and Patented Digital Twin Platform which becomes digital
            environment of the Client plant, for Bert Optimus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> Reinforcement
            Learning Agents to be trained for optimization of Energy Consumption
            and improving Product Efficiency. And for visual representation of
            the Client plant on Bert Nova<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> Dashboards
          </Text>
          <Box
            display={{ lg: "flex", md: "flex" }}
            marginTop={"40px"}
            alignItems={"center"}
          >
            <Box
              marginLeft={{ lg: "70px", sm: "35px" }}
              fontSize={{ lg: "20px", base: "16px" }}
              fontWeight={"500"}
            >
              <UnorderedList>
                <ListItem>
                  <Text>Pharma Formulation clean rooms</Text>
                </ListItem>
                <ListItem>
                  <Text>Pharma API clean rooms</Text>
                </ListItem>
                <ListItem>
                  <Text>Specialty Chemicals</Text>
                </ListItem>
                <ListItem>
                  <Text>Chemical Soda Ash</Text>
                </ListItem>
                <ListItem>
                  <Text>Chemical Caustic Soda</Text>
                </ListItem>
                <ListItem>
                  <Text>Cement Pyro</Text>
                </ListItem>
                <ListItem>
                  <Text>Automobile four-wheeler paint shop</Text>
                </ListItem>
                <ListItem>
                  <Text>Automobile two-wheeler paint shop</Text>
                </ListItem>
                <ListItem>
                  <Text>Commercial Building</Text>
                </ListItem>
              </UnorderedList>
            </Box>
            <Box width={{ lg: "50%", md: "50%", base: "100%" }}>
              <Image
                src={models}
                marginLeft={{ base: "20px", lg: "197px" }}
                marginTop={{ base: "15px" }}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Image src={screens} width={{ base: "100%", lg: "70%" }} />
          </Box>
        </Box>
      
     
        <Box
          marginTop={"25px"}
          borderRadius={{ base: "17px", lg: "25px" }}
          padding={"15px"}
        > <section id="bert-optimus" className="scroll">
          <Text fontSize={{ lg: "34px", base: "17px" }} fontWeight={"bold"}>
            Bert Optimus
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
          </Text></section>{" "}
          <Text fontSize={{ lg: "20px", base: "16px" }}>
            The brain of Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, which is a proprietary,
            patented Multi-Agent Deep Learning & Reinforcement Learning
            Hierarchical Platform, for real time computation and control
            commands to be sent to Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> - Bert Maximus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>, to actuate
            controls at Process Line/Utility Equipment & Machines and bring-
            30%+ Energy Efficiency Improvement for Plants, 3%+ Production
            Efficiency in Process Lines of Plants, Improvement in life of
            Process Equipment by 2 years.
          </Text>
          <Box
            display={{ lg: "flex", md: "flex", base: "block" }}
            mt={"17px"}
            alignItems={"center"}
          >
            <Box
              fontSize={{ lg: "20px", base: "16px" }}
              width={{ lg: "50%", md: "50%", base: "100%" }}
              fontWeight={"500"}
            >
              <UnorderedList>
                <ListItem>
                  <Text>
                    Self-learning platform enabled by real-time, actual,
                    granular data and fundamental physics of the
                    machine/chemical reactions in a process
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>Trained baseline models and transfer learning</Text>
                </ListItem>
                <ListItem>
                  <Text>
                    Multi-agent Hierarchical consensus driven/Global consensus
                    driven, several layers of abstractions built in the platform
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    360° fully automated, objective-driven real-time controls
                  </Text>
                </ListItem>
              </UnorderedList>
            </Box>
            <Box
              mt={"27px"}
              ml={{ base: "16px", lg: "75px" }}
              width={{ lg: "50%", md: "50%", base: "100%" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <Image src={RLA} />
            </Box>
          </Box>
          <Box margin={{ base: "47px 1px 0px 1px", lg: "47px 100px 0px 100px" }}  display={"flex"}
              justifyContent={"center"}>
            <Box>
              <Image src={DTR} />
              <Box display={"flex"} justifyContent={"center"}>
                <Text
                  // ml={{ base: "0px", lg: "-117px" }}
                  fontFamily={"monospace"}
                  mt={"20px"}
                >
                  Reinforcement Learning Agent Generated Output
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      
      <Box
        borderRadius={{ base: "17px", lg: "25px" }}
        padding={"15px"}
        mt={"25px"}
      >
        <Box>
          <Text
            fontSize={{ lg: "34px", base: "17px" }}
            fontWeight={"900"}
            marginTop={"70px"}
          >
            Data Security - Bert Platform Solution
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
          </Text>
        </Box>
        <SimpleGrid
          templateColumns={`repeat(${columnCount}, 1fr)`}
          gap={4}
          margin={"10px"}
          justifyItems="center" // Har grid item ko horizontal center mein layega
          alignContent="center" // Rows ko vertical center mein layega
          marginTop={"70px"}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              flexWrap="wrap"
              justifyContent="center"
              width={"auto"}
            >
              <Image
                src={img.src}
                alt={`Image ${index + 1}`}
                width={"180px"}
                margin={"2px"}
              />
              <Box>
                <Text textAlign={"center"} height={"auto"}>
                  {img.title}
                </Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Products;
