import { Box, Text, Image, Link } from "@chakra-ui/react";
import React from "react";
import arrow from "../assets/Products/Group 4226.png";
import arrow2 from "../assets/Products/Group 4227.png";
import arrow3 from "../assets/Products/Group 4228.png";
import arrow4 from "../assets/Products/Group 4229.png";

const Allprod = () => {
  return (
    <Box display={{ base: "none", lg: "block" }} textAlign={"center"}>
      <Link href="https://bertmaximus.bertlabs.com">
        <Box
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
          }}
          background={"#E6F7D6"}
          margin={"auto"}
          padding={"50px"}
          gap={"25px"}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)"
          borderRadius={"17px"}
          width={"37%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text color={"#D63E3E"} fontSize={"28px"} fontWeight={"700"}>
            Bert Maximus
            <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>
              &#174;
            </span>
          </Text>
          <Text fontSize={"18px"} fontWeight={"500"}>
            Suite of 7 Proprietary, Patented IoT devices, to real time sense and
            control
          </Text>
        </Box>
      </Link>
      <Box display={"flex"}>
        <Image src={arrow} ml={"25%"} />
        <Image src={arrow2} ml={"32%"} />
      </Box>

      <Box mt="15" display={"flex"}>
        <Box
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
          }}
          background={"#E6F7D6"}
          margin={"auto"}
          padding={"50px"}
          gap={"25px"}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)"
          borderRadius={"17px"}
          width={"37%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text color={"#D63E3E"} fontSize={"28px"} fontWeight={"700"}>
            Bert Nova
            <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
          </Text>
          <Text fontSize={"18px"} fontWeight={"500"}>
            {" "}
            Proprietary, patented Multi-tenant Distributed Microservices
            Computing Platform for the data storage, data processing, data
            visual analytics
          </Text>
        </Box>

        <Box fontSize={"25px"} mt={20} fontWeight={"bold"}>
          360° Suite
        </Box>
        <Link href="https://bertmaximus.bertlabs.com">
          <Box
            _hover={{
              transform: "scale(1.05)",
              transition: "transform 0.3s ease-in-out",
            }}
            background={"#E6F7D6"}
            margin={"auto"}
            padding={"50px"}
            gap={"25px"}
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)"
            borderRadius={"17px"}
            width={"37%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text color={"#D63E3E"} fontSize={"28px"} fontWeight={"700"}>
              Bert Qrious
              <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>
                &#174;
              </span>
            </Text>
            <Text fontSize={"18px"} fontWeight={"500"}>
              Proprietary, patented IoT powered Wireless Sensor Network for data
              transmission
            </Text>
          </Box>
        </Link>
      </Box>
      <Box display={"flex"}>
        <Image src={arrow3} ml={"25%"} mt={"20px"} />
        <Image src={arrow4} ml={"32%"} mt={"20px"} />
      </Box>

      <Box mt={"15"} display={"flex"}>
        <Box
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
          }}
          background={"#E6F7D6"}
          margin={"auto"}
          padding={"50px"}
          gap={"25px"}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)"
          borderRadius={"17px"}
          width={"77%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"50%"}
            gap={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign="center"
          >
            <Text color={"#D63E3E"} fontSize={"28px"} fontWeight={"700"}>
              Bert Optimus
              <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>
                &#174;
              </span>
            </Text>
            <Text fontSize={"18px"} fontWeight={"500"}>
              Proprietary, patented Multi-Agent Deep Learning & Reinforcement
              Learning Hierarchical Platform, for real time computation and
              control commands
            </Text>
          </Box>
          <Box height={"150px"} width={"2px"} background={"gray"}></Box>
          <Box
            display={"flex"}
            width={"50%"}
            gap={"25px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign="center"
          >
            <Text color={"#D63E3E"} fontSize={"28px"} fontWeight={"700"}>
              Bert Geminus
              <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>
                TM
              </span>
            </Text>
            <Text fontSize={"18px"} fontWeight={"500"}>
              Proprietary, Patented Digital Twin Platform which becomes digital
              environment and provides visual representation of Client plant
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Allprod;
